import React, { useState, useEffect } from "react";
import "./DonationForm.css";
import axios from "axios";

const DonationForm = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [formData, setFormData] = useState({
    amount: "",
    name: "",
    email: "",
    mobile: "",
    dob: "",
    want80G: false,
    receivePrasad: false,
  });

  const donationOptions = [
    { amount: 1500, description: "Feed a cow for a week" },
    { amount: 2500, description: "Feed a cow for 2 weeks" },
    { amount: 5500, description: "Feed a cow for 3 weeks" },
    { amount: 7500, description: "Feed a cow for a month" },
    // { amount: 7500, description: "Feed a cow for a month" },
  ];

  const handleOptionClick = (amount) => {
    setSelectedOption(amount);
    setFormData((prev) => ({
      ...prev,
      amount: amount.toString(),
    }));
  };

  const handleChange = (e) => {
    if (e.target.name === "amount") {
      setSelectedOption(null);
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, type, checked, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "amount") {
      setSelectedOption(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      formData.amount === "" ||
      formData.name === "" ||
      formData.email === "" ||
      formData.mobile === "" ||
      formData.dob === ""
    ) {
      alert("Please enter all required fields");
      return;
    }

    const totalAmount = getTotalAmount();
    let isFormDataStored = false; // Flag to prevent duplicate storage

    const formData2 = {
      amount: formData.amount,
      name: formData.name,
      email: formData.email,
      mobile: formData.mobile,
      dob: formData.dob,
      want80G: formData.want80G ? "Yes" : "No",
      receivePrasad: formData.receivePrasad ? "Yes" : "No",
      submissionDate: new Date().toISOString(),
    };

    try {
      // Create order on backend
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payment/create-order`,
        {
          amount: totalAmount,
          currency: "INR",
        }
      );

      const order = response.data;
      let sevaDescription = "Gau Poshana Seva";

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: totalAmount,
        currency: "INR",
        name: "Gau Poshana Seva",
        description: sevaDescription,
        order_id: order.id,
        notes: {
          donorName: formData2.name,
          donorPhoneNumber: formData2.mobile,
          donorEmail: formData2.email,
          donorDob: formData2.dob,
          // sevaDate: formData.sevaDate,
          // honoureeName: formData.honoureeName,
          // relationship: formData.relationship,
        },
        handler: async function (response) {
          try {
            // Verify payment
            const verificationResponse = await axios.post(
              `${process.env.REACT_APP_API_URL}/api/payment/verify-payment`,
              {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                donationDetails: { ...formData2, amount: totalAmount },
              }
            );

            if (verificationResponse.data.success) {
              // if (!isFormDataStored) {
              await storeFormData("Payment Successful");
              isFormDataStored = true; // Mark data as stored
              alert("Payment Successful!");
              // }
            }
          } catch (err) {
            console.error(err);
            alert("Payment verification failed");
            if (!isFormDataStored) {
              await storeFormData("Payment Failed");
              isFormDataStored = true; // Mark data as stored
            }
          }
        },
        prefill: {
          name: formData.name,
          email: formData.email,
          contact: formData.mobile,
        },
        theme: { color: "#C61D21" },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

      paymentObject.on("payment.failed", async function (response) {
        alert("Payment Failed: " + response.error.description);
        if (!isFormDataStored) {
          await storeFormData("Payment Failed");
          isFormDataStored = true; // Mark data as stored
        }
      });
    } catch (error) {
      console.error("Error initiating payment:", error);
      alert("Failed to initiate payment. Please try again.");
      if (!isFormDataStored) {
        await storeFormData("Payment Failed");
        isFormDataStored = true; // Mark data as stored
      }
    }
  };

  const storeFormData = async (paymentStatus) => {
    const modifiedFormData = {
      ...formData,
      want80G: formData.want80G ? "Yes" : "No",
      receivePrasad: formData.receivePrasad ? "Yes" : "No",
      paymentStatus: paymentStatus,
    };

    const formBody = new URLSearchParams(modifiedFormData).toString();

    console.log(formBody);

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbzxYif8qOVlVduU4e4dPx18NaFI7SxLzOyIHpd1btJERywNXgC3dS2e19pLOmIQC-yvZQ/exec",
        {
          method: "POST",
          body: formBody,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      setFormData({
        amount: "",
        name: "",
        email: "",
        mobile: "",
        dob: "",
        want80G: false,
        receivePrasad: false,
      });
    } catch (error) {
      // setMessage("An error occurred while submitting the form.");
      console.error("Form submission error:", error);
    } finally {
      // setIsSubmitting(false);
      // setTimeout(() => setMessage(""), 2600);
    }
  };

  const getTotalAmount = () => {
    return formData.amount;
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="donation-container">
      <div className="donation-box">
        <h1 className="form-header">Offer Gau Poshana Seva</h1>

        <div className="donation-options">
          {donationOptions.map((option, index) => (
            <div
              key={index}
              className={`option cursor-pointer duration-200 ${
                selectedOption === option.amount
                  ? "bg-[#fac785] text-black"
                  : "bg-red !border-orange-500"
              }`}
              onClick={() => handleOptionClick(option.amount)}
            >
              Rs {option.amount.toLocaleString()} <br /> ({option.description})
            </div>
          ))}
        </div>

        <form
          onSubmit={handleSubmit}
          className="flex flex-col justify-center items-center md:block"
        >
          <label>
            Donation Amount<span className="required">*</span>
          </label>
          <input
            type="text"
            name="amount"
            value={formData.amount}
            onChange={handleChange}
            placeholder="Donation Amount..."
            required
            className="dona"
            style={{
              padding: "2px 10px",
              marginBottom: "5px",
            }}
          />

          <div className="form-group">
            <div className="form-col">
              <label>
                Name<span className="required">*</span>
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>
            <div className="form-col">
              <label>
                Email<span className="required">*</span>
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>
          </div>

          <div className="form-group">
            <div className="form-col">
              <label>
                Mobile Number<span className="required">*</span>
              </label>
              <input
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                placeholder="10-digit number"
                required
              />
            </div>
            <div className="form-col hidden md:block">
              <label>
                Date Of Birth<span className="required">*</span>
              </label>
              <input
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="checkbox-section">
            <label>
              <input
                type="checkbox"
                name="want80G"
                checked={formData.want80G}
                onChange={handleCheckboxChange}
              />
              <span className="checkbox-label">
                I would like to receive 80G Certificate
              </span>
            </label>
            <label>
              <input
                type="checkbox"
                name="receivePrasad"
                checked={formData.receivePrasad}
                onChange={handleCheckboxChange}
              />
              <span className="checkbox-label">
                Please enter your address for receiving Prasadam (Only within
                India)
              </span>
            </label>
          </div>

          <button type="submit" className="donate-button">
            DONATE NOW
          </button>
        </form>

        <p>Avail 80G tax exemption to your donation</p>
      </div>
    </div>
  );
};

export default DonationForm;
